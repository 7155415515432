const apiBaseUrl = import.meta.env.VITE_WALLET_API_BASE_URL;

const headers = {
  'Content-Type': 'application/json',
};

const fetchHelper = {
  get: async (endpoint, params = {}, token = '') => {
    try {
      const queryString = new URLSearchParams(params).toString();
      const response = await fetch(`${apiBaseUrl}${endpoint}?${queryString}`, {
        method: 'GET',
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  post: async (endpoint, data = {}, token = '') => {
    try {
      const response = await fetch(`${apiBaseUrl}${endpoint}`, {
        method: 'POST',
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default fetchHelper;
