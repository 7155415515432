import SelectBox from '@common/src/common/selectbox/selectbox'
import Toggle from '@common/src/common/Toggle/Toggle'
import Pageheader from '@common/src/pageheader/pageheader'
import { updateCountryConfig } from '@common/src/service/cloud/settings'
import { getCountries } from '@common/src/service/firebase/api'
import { useToast } from '@common/src/common/Toast/ToastProvider';

import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'

const ValuePlusCardConfiguration = () => {
    const toast = useToast();
    const [selectedCountry, setSelectedCountry] = useState("")
    const [countries, setCountries] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [valuePlusAvailable, setValuePlusAvailable] = useState(false)

    const [initialValues, setInitialValues] = useState({
        valuePlus: {
            type: "IN",
            available: selectedCountry.valuePlus?.available || false
        }
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [countryList] = await Promise.all([getCountries()])
                const getCountryData = countryList?.map((doc) => ({
                    value: doc?.iso,
                    label: doc?.name,
                    id: doc?.id,
                    valuePlus: doc?.valuePlus || { type: "IN", available: false }
                }));
                setCountries(getCountryData)
            } catch (error) {
                console.error('Failed to fetch data:', error)
            }
        }
        fetchData();
    }, [selectedCountry])

    const onChange = (obj) => {
        setInitialValues({
            valuePlus: {
                type: obj.valuePlus?.type || "IN",
                available: obj.valuePlus?.available || false
            }
        })
        setValuePlusAvailable(obj.valuePlus?.available || false)
        setSelectedCountry(obj)
    }

    const handleDataSubmit = async (values) => {
        setIsLoading(true)
        values.country = selectedCountry.id
        await updateCountryConfig(values);
        toast.success("Value Plus Card configuration updated successfully");
        setIsLoading(false);
    }

    const renderForm = () => {
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={handleDataSubmit}
            >
                {({ errors, touched,setFieldValue }) => (
                    <Form>
                        <div className=" grid grid-cols-12 gap-6 ">
                            <div className="xl:col-span-12 col-span-12">
                                <div className="grid grid-cols-1 gap-6">
                                    <div className=" grid-cols-2 grid gap-6">

                                        <label htmlFor="country" className="form-label">SELECT COUNTRY</label>
                                        <SelectBox
                                            id="country"
                                            name="country"
                                            className="ti-form-select rounded-sm !p-0"
                                            placeholder="Choose Country"
                                            options={countries}
                                            defaultValue={selectedCountry || countries?.[0]}
                                            onChange={onChange}
                                            errorText={touched.country && errors.country ? errors.country : ''}
                                        />
                                    </div>
                                </div>
                                {selectedCountry &&
                                    <>
                                        <div className="xl:col-span-4 col-span-12">
                                            <Toggle
                                                name="valuePlus.available"
                                                label="Enable Value Plus Card"
                                                value={valuePlusAvailable}
                                                onChange={(e) => {
                                                    setFieldValue("valuePlus.available", e.target.checked);
                                                    setValuePlusAvailable(e.target.checked);
                                                }}
                                            />
                                        </div>

                                        <div className="flex justify-end">

                                            <button  type="submit" className=" m-1 ti-btn ti-btn-primary ti-btn-loader" disabled={isLoading}>
                                                {isLoading && (
                                                    <span className="loading">
                                                        <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                                                    </span>
                                                )}
                                                {isLoading ? <span className="me-2">Loading</span> : "Submit"}
                                            </button>
                                        </div>
                                    </>
                                }
                            </div>

                        </div>
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <>
            <Pageheader currentpage="Value Plus Card Configuration" activepage="Value Plus Card Configuration" mainpage="Settings" />

            <div className=" grid-cols-12 gap-6 grid">
                <div className="xl:col-span-12 col-span-12">
                    <div className="box">
                        <div className="add-products box-body  !p-0">
                            <div className="p-6">
                                {renderForm()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ValuePlusCardConfiguration