import { useEffect, useRef, useState } from 'react'
import PageHeader from '@common/src/pageheader/pageheader'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas'
import Toggle from '@common/src/common/Toggle/Toggle'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { ALL_OPTION, INITIAL_PAGINATION_PROPS, STATUS_OPTIONS } from '@hub/constants/constants'
import ModifierGroupForm from './modifierGroupForm'
import { useSelector } from 'react-redux'
import { COMPANY_ID } from '@common/src/constants/constants'
import { modifierGroupAction } from '@common/src/service/cloud/catalog'
import FilterDropdown from '@common/src/common/inputbox/filterDrop'
import SearchBox from '@common/src/common/inputbox/searchBox'
import { calcOffset } from '@common/src/common/helper'
import { GET_ALL_MODIFIER_GROUPS } from '@common/src/lib/graphql/query/catalog'
import { useQuery } from '@apollo/client'
import Pagination from '@hub/components/common/Pagination/pagination'
import LoaderSm from '@common/src/common/loader/loader-sm'

const ModifierGroup = () => {
  const tableRef = useRef(null)
  const { selectedCompany } = useSelector((state) => state.auth)
  const [pagination, setPagination] = useState(INITIAL_PAGINATION_PROPS)
  const [isEdit, setIsEdit] = useState(false)
  const [searchText, setSearchText] = useState('')
  const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()
  const toast = useToast()
  const [listInfo, setListInfo] = useState('No Modifier Groups found.')
  const [modGroupStatus, setModGroupStatus] = useState(ALL_OPTION.common.value)
  const [modGroups, setModGroups] = useState([])
  const [modGroupData, setModGroupData] = useState(null)
  const [modGroupStatusLoader, setModGroupStatusLoader] = useState({ loading: false, id: null })


  const { data, refetch, loading } = useQuery(GET_ALL_MODIFIER_GROUPS, {
    variables: {
      offset: calcOffset(searchText, pagination.currentPage, pagination.limit, pagination.totalRecords),
      limit: pagination.limit,
      companyId: selectedCompany?.value,
      status: modGroupStatus?.toUpperCase(),
      search: searchText || undefined,
    },
    fetchPolicy:'no-cache',
    onCompleted: (data) => {
      if (searchText) {
        setPagination((prevPagination) => ({
          ...prevPagination,
          totalRecords: data?.categoryQuery?.totalRecords,
          offset: calcOffset(searchText, pagination.currentPage, pagination.limit, pagination.totalRecords),
          currentPage: 1,
        }))
      }
    },
  })
  useEffect(() => {
    if (data) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: data.modifierGroupQuery.totalPages,
      }))
      setModGroups(data.modifierGroupQuery.rows)
    } else {
      setModGroups([])
      searchText && setListInfo('No Modifier group found for your search.')
    }
  }, [data])

  useEffect(() => {
    refetch({
      offset: calcOffset(searchText, pagination.currentPage, pagination.limit, pagination.totalRecords),
      limit: pagination.limit,
      ...(searchText && { search: searchText }),
      ...(selectedCompany?.id && { companyId: parseInt(selectedCompany.id) }),
      ...modGroupStatus && { status: modGroupStatus.toUpperCase() },
    });
    setPagination(INITIAL_PAGINATION_PROPS)
  }, [searchText, selectedCompany, modGroupStatus])

  // Centralized function to handle status update
  const handleStatusUpdate = async (status, record) => {
    setModGroupStatusLoader({ loading: true, id: record.id })
    const formData = { ...record, status, ...(record?.id && { id: parseInt(record?.id) }) }
    try {
      await modifierGroupAction(formData, true)
      toast.success(`Modifier group status updated to ${status.toLowerCase()}`)
      refetch()
    } catch (error) {
      refetch()
      toast.error('Error updating Modifier group')
      setModGroupStatusLoader({ loading: false, id: '' })
    }
    finally {
      refetch()
      setModGroupStatusLoader({ loading: false, id: '' })
    }
  }

  const onEditClick = (data) => {
    setModGroupData(data)
    setIsEdit(true)
    openOffCanvas('addModifierGroup', 'Edit Modifier Group')
  }
  // Updated status toggle logic
  const onChangeStatus = (record) => {
    const newStatus = record.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    handleStatusUpdate(newStatus, record)
  }

  const onComplete = () => {
    refetch()
  }

  const onClose = () => closeOffCanvas(false)
  const onAddClick = () => {
    setModGroupData(null)
    setIsEdit(false)
    openOffCanvas('addModifierGroup', 'Add Modifier Group')
  }

  return (
    <>
      <PageHeader currentpage="Modifier Groups" activepage="Catalog Management" mainpage="Modifier Groups" />
      <div className="grid gap-6 grid-cols-12">
        <div className="xl:col-span-12 col-span-12">
          <div className="box">
            <div className="flex justify-between box-header items-center">
              <div className="box-title">Modifier Groups</div>
              <div className="flex items-center">
                <FilterDropdown
                  className="mr-2 w-[200px]"
                  options={[ALL_OPTION.status, ...STATUS_OPTIONS]}
                  value={STATUS_OPTIONS.find((opt) => opt.value === modGroupStatus) || ALL_OPTION.status}
                  onChange={(e) => { setModGroupStatus(e.value.toUpperCase()) }}
                />
                <SearchBox
                  value={searchText}
                  placeHolder="Search by name or POS ID"
                  onChange={(e) => setSearchText(e.target.value)}
                  classes='!py-2 !px-2 !mr-2'
                />
              </div>
              <div className="flex items-center">
                <button
                  onClick={onAddClick}
                  type="button"
                  className="ti-btn !py-2 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary ms-2 !mb-0"
                >
                  <i className="ri-add-line font-semibold align-middle me-1"></i>Add Modifier Group
                </button>
              </div>
            </div>
            <div className="box-body">
              <div className="table-responsive mb-4">
                <table className="table whitespace-nowrap table-bordered min-w-full">
                  <thead>
                    <tr className="border-b border-defaultborder">
                      <th className="text-start">Sl.No</th>
                      <th className="text-start">Name</th>
                      <th className="text-start">POS ID</th>
                      <th className="text-start">Status</th>
                      <th className="text-start">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {modGroups?.length > 0 ? (
                          modGroups.map((i) => (
                            <tr className="company-list border-b" key={i.id}>
                              <td>{(pagination.currentPage - 1) * pagination.limit + modGroups.indexOf(i) + 1}</td>
                              <td>
                                <div className="flex items-center">{i?.modGroupName?.en}</div>
                              </td>
                              <td>
                                <div className="flex items-center">{i?.posId}</div>
                              </td>
                              <td>
                                <div className="flex items-center">{i?.status}</div>
                              </td>
                              <td>
                                <div className="space-x-1 rtl:space-x-reverse flex items-center">
                                  <button onClick={() => onEditClick(i)} className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm">
                                    <i className="ri-pencil-line"></i>
                                  </button>
                                  <div className="relative inline-flex items-center">
                                    <Toggle label="" onChange={() => onChangeStatus(i)} value={i.status === STATUS_OPTIONS[0].value} />
                                    {(modGroupStatusLoader.loading && modGroupStatusLoader.id === i.id) && (
                                      <div className="absolute inset-0 bg-opacity-50 bg-gray-100 w-full ml-3">
                                        <LoaderSm />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={8}>
                              <div className="alert alert-info">{listInfo}</div>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                pagination={pagination}
                setPagination={setPagination}
                rows={modGroups}
                searchQuery={searchText}
                status={modGroupStatus}
              />
            </div>
          </div>

        </div>
      </div>
      <FloatingCanvas onClose={onClose} isVisible={isVisible} close={closeOffCanvas} title={offCanvasTitle}>
        {activeComponent === 'addModifierGroup' && (
          <ModifierGroupForm data={modGroupData} isEdit={isEdit} onComplete={onComplete} companyId={selectedCompany?.value || COMPANY_ID} />
        )}
      </FloatingCanvas>
    </>
  )
}

export default ModifierGroup
