import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import Pageheader from '@common/src/pageheader/pageheader'
import { updateCountryConfig } from '@common/src/service/cloud/settings'
import { getCountries } from '@common/src/service/firebase/api'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useToast } from '@common/src/common/Toast/ToastProvider';
import * as Yup from 'yup'

const validationSchema = Yup.object({
    coinValue: Yup.number().required('Percentage of Bitecoin Earned value is required').min(0, 'Percentage of Bitecoin Earned must be greater than zero').max(100,'Percentage value must not exceed 100.',),
    minCoin: Yup.number().required('Minimum Bitecoin Required value is required').min(0, 'Minimum Bitecoin Required must be greater than zero'),
    conversion: Yup.number().required('Bitecoin Value Earned is required').min(0, 'Bitecoin Value Earned count must be greater than zero'),

})
const BitecoinConfiguration = () => {
    const toast = useToast();
    const [selectedCountry, setSelectedCountry] = useState("")
    const [countries, setCountries] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    // const [biteCoinConfig, setBiteCoinConfig] = useState(false);
    const [initialValues, setInitialValues] = useState({
        biteCoinConfig: false
    })
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [countries] = await Promise.all([getCountries()])
                const getCountryData = countries?.map((doc) => ({
                    value: doc?.iso,
                    label: doc?.name,
                    id: doc?.id,
                    biteCoinData: doc?.config?.biteCoinConfig,
                    currencyCode: doc?.currencyCode
                }));
                setCountries(getCountryData)
            } catch (error) {
                console.error('Failed to fetch data:', error)
            }
        }
        fetchData();
    }, [])


    const onChange = (obj) => {
        setInitialValues({
            coinValue: obj?.biteCoinData?.coinValue,
            conversion: obj?.biteCoinData?.conversion,
            minCoin: obj?.biteCoinData?.minCoin
        })
        // setBiteCoinConfig(!!obj?.biteCoinData,)
        setSelectedCountry(obj)
    }

    const handleSubmit = async (values) => {
        const { coinValue, conversion, minCoin } = values;
        const newValues = {
            coinValue: parseFloat(coinValue),
            conversion: parseFloat(conversion),
            minCoin: parseFloat(minCoin)
        }

        setIsLoading(true)
        await updateCountryConfig({ country: selectedCountry.id, biteCoinConfig: newValues });
        setCountries(countries.map(item => item.id === selectedCountry.id ? { ...item, biteCoinData: newValues } : item));
        toast.success("Bitecoin configuration updated successfully");
        setIsLoading(false);
        fetchData();
    }

    return (
        <>
            <Pageheader currentpage="Bitecoin Configuration" activepage="Settings" mainpage="Bitecoin Configuration" />

            <div className="grid grid-cols-12 gap-6">
                <div className="xl:col-span-12 col-span-12">
                    <div className="box">
                        <div className="box-body add-products !p-0">
                            <div className="p-6">
                                <Formik
                                    enableReinitialize
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                    validationSchema={validationSchema}

                                >
                                    {({ setFieldValue, values, errors, touched }) => (
                                        <Form>
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="xl:col-span-12 col-span-12">
                                                    <div className="grid grid-cols-1 gap-6">
                                                        <div className="grid grid-cols-2 gap-6">

                                                            <label htmlFor="country" className="form-label">SELECT COUNTRY</label>
                                                            <SelectBox
                                                                id="country"
                                                                name="country"
                                                                className="ti-form-select rounded-sm !p-0"
                                                                placeholder="Choose Country"
                                                                options={countries}
                                                                defaultValue={selectedCountry || countries?.[0]}
                                                                onChange={onChange}
                                                                errorText={touched.country && errors.country ? errors.country : ''}
                                                            />
                                                        </div>
                                                    </div>
                                                    {selectedCountry &&
                                                        <>
                                                            {/* <div className="xl:col-span-4 col-span-12">
                                                                <Toggle
                                                                    label="Do you want to configure bitecoin in the country"
                                                                    name="biteCoinConfig"
                                                                    value={biteCoinConfig}
                                                                    onChange={(e) => {
                                                                        setFieldValue("biteCoinConfig", e.target.checked);
                                                                        setBiteCoinConfig(e.target.checked);
                                                                    }}
                                                                />
                                                            </div> */}
                                                           
                                                                {/* // <div className="box-header flex items-center justify-between"> */}
                                                                <div className="box-body add-products !p-0">
                                                                    <div className="grid grid-cols-1 gap-6">
                                                                        <InputBox
                                                                            placeholder="Enter value "
                                                                            type="number"
                                                                            id="coinValue"
                                                                            name="coinValue"
                                                                            label="Percentage of Bitecoin Earned"
                                                                            value={values.coinValue}
                                                                            onChange={(e) => setFieldValue('coinValue', e.target.value)}
                                                                            errorText={touched.coinValue && errors.coinValue ? errors.coinValue : null}
                                                                            required={true}
                                                                            step="0.01"
                                                                            
                                                                        />
                                                                    </div>

                                                                    <div className="grid grid-cols-1 gap-6">
                                                                        <InputBox
                                                                            name="minCoin"
                                                                            label="Minimum Bitecoin Required"
                                                                            placeholder="Enter value "
                                                                            value={values.minCoin}
                                                                            onChange={(e) => setFieldValue('minCoin', e.target.value)}
                                                                            errorText={touched.minCoin && errors.minCoin ? errors.minCoin : null}
                                                                            type="number"
                                                                            id="minCoin"
                                                                            required={true}
                                                                            step="0.01"
                                                                            
                                                                        />
                                                                    </div>
                                                                    <div className="grid grid-cols-1 gap-6">
                                                                        <InputBox
                                                                            type="number"
                                                                            placeholder={`1 Bitecoin = ${selectedCountry.currencyCode}`}
                                                                            value={values.conversion}
                                                                            onChange={(e) => setFieldValue('conversion', e.target.value)}
                                                                            errorText={touched.conversion && errors.conversion ? errors.conversion : null}
                                                                            id="conversion"
                                                                            name="conversion"
                                                                            label="Bitecoin Value "
                                                                            required={true}
                                                                            step="0.01"
                                                                            
                                                                        />
                                                                    </div>
                                                                </div>                                                
                                                            <div className="flex justify-end mt-3">

                                                                <button type="submit" disabled={isLoading} className="ti-btn ti-btn-primary ti-btn-loader m-1">
                                                                    {isLoading ? <span className="me-2">Loading</span> : "Submit"}
                                                                    {isLoading && (
                                                                        <span className="loading">
                                                                            <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </>
                                                    }

                                                </div>

                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default BitecoinConfiguration