import InputBox from '@common/src/common/inputbox/inputbox';
import SearchBox from '@common/src/common/inputbox/searchBox';
import SelectBox from '@common/src/common/selectbox/selectbox';
import EmptyData from '@common/src/common/Table/emptyData';
import TextBreaker from '@common/src/common/Table/textBreaker';
import { GET_ALL_CUSTOMERS } from '@common/src/lib/graphql/query/customers';
import Pagination from '@hub/components/common/Pagination/pagination';
import { CUSTOMER_STATUS_OPTIONS, CUSTOMER_STATUS } from '@hub/constants/constants';
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client';
import Pageheader from '@common/src/pageheader/pageheader';
import Toggle from '@common/src/common/Toggle/Toggle';
import Loader from '@common/src/common/loader/loader';
import { blockCustomer } from '@common/src/service/cloud/customer';
import { useToast } from '@common/src/common/Toast/ToastProvider';
import dayjs from 'dayjs';

const CustomerList = () => {
    const [customers, setCustomers] = useState([])
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('')
    const [searchItem, setSearchItem] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedUser, setSelectedUser] = useState("")
    const [searchQuery, setSearchQuery] = useState('');
    const [blockLoading,setBlockLoading] = useState(false)
    const [appliedFilters, setAppliedFilters] = useState({
        status: undefined,
        startDate: undefined,
        endDate: undefined,
    });
    const toast = useToast()


    const [pagination, setPagination] = useState({
        limit: 20,
        totalPages: 0,
        currentPage: 1,
        offset: 0,
    })


    const { data, loading, refetch, error } = useQuery(GET_ALL_CUSTOMERS, {
        variables: {
            offset: pagination.offset,
            limit: pagination.limit,
            search: searchQuery || undefined,
            filters: appliedFilters,
        },
    });

    useEffect(() => {
        if (data && !loading && !error) {
            setCustomers(data.customerListQuery.rows);
            setPagination((prevPagination) => ({
                ...prevPagination,
                totalPages: data.customerListQuery.totalPages,
            }))
        } else {
            setCustomers([])
        }
    }, [data, loading, error]);

    const handleApplyFilters = () => {
        setAppliedFilters({
            status: selectedStatus.value || undefined,
            startDate: selectedStartDate || undefined,
            endDate: selectedEndDate || undefined,
        });
        refetch(); // Fetch data only when the button is clicked
    };

    const clearFilters = () => {
        setSelectedStatus([]);
        setSelectedStartDate("");
        setSelectedEndDate("");
        setAppliedFilters({
            status: undefined,
            startDate: undefined,
            endDate: undefined,
        });
        refetch(); // Fetch updated data after clearing
    };


    const handleSearchChange = (newSearchItem) => {
        setSearchQuery(newSearchItem);
    };
    const handleBlockOrUnblockClick = (data) => {
        setSelectedUser(data)
        setIsModalVisible(true)
    }

    const handleBlockOrUnblock = async () => {
        setBlockLoading(true)
        try {
            if (selectedUser.status == CUSTOMER_STATUS.BLOCKED) {
                await blockCustomer({ uid: selectedUser.id, status: "UNBLOCKED" })
                toast.success(`Customer is unblocked successfully`)
            } else {
                await blockCustomer({ uid: selectedUser.id, status: "BLOCKED" })
                toast.success(`Customer is blocked successfully`)
            }
            setIsModalVisible(false)
            setBlockLoading(false)
            refetch()
        } catch (e) {
            console.log(e);

            toast.error('Error Blocking Customer')
        }
    }

    if (loading) return <Loader />

    return (
        <>
            <Pageheader activepage="Customer Management" mainpage="customer" currentpage="Customers" />
            <div className="grid gap-6 grid-cols-12">
                <div className="xl:col-span-12 col-span-12">
                    <div className="box">
                        <div className="flex justify-between box-header items-center">
                            <div className="flex mr-4 w-[27%]">
                                <SearchBox
                                    classes="py-2 px-3 !ti-form-input !rounded-none !rounded-s-sm !focus:z-10"
                                    width="w-full"
                                    placeHolder="Search by Name/Email/Phone number"
                                    value={searchItem}
                                    onChange={(e) => {
                                        setSearchItem(e.target.value); // Update the local state
                                        if (!e.target.value.trim()) {
                                            
                                            setSearchQuery('')//reset
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        // Perform search when Enter key is pressed
                                        if (e.key === 'Enter') {
                                            setSearchQuery(searchItem.trim())
                                        }
                                    }}
                                />
                                <button className="inline-flex flex-shrink-0 justify-center p-2 items-center rounded-e-sm border border-transparent font-semibold bg-primary text-white hover:bg-primary focus:z-10 focus:outline-none focus:ring-0 focus:ring-primary transition-all text-sm"
                                    onClick={() => handleSearchChange(searchItem.trim())} >
                                    <i className="ri-search-line"></i>
                                </button>
                            </div>
                            <div className="flex">
                                <SelectBox
                                    options={CUSTOMER_STATUS_OPTIONS}
                                    placeholder="Select Status"
                                    name="Status"
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(e)}
                                    className="mr-2 min-w-[200px]"
                                />

                                <InputBox
                                    type="date"
                                    name="startDate"
                                    value={selectedStartDate}
                                    onChange={(e) => setSelectedStartDate(e.target.value)}
                                    placeholder="Enter Start Date"
                                    id="startDate"
                                    className="mr-2 min-w-[200px]"
                                />
                                <InputBox
                                    type="date"
                                    name="endDate"
                                    value={selectedEndDate}
                                    onChange={(e) => setSelectedEndDate(e.target.value)}
                                    placeholder="Enter End Date"
                                    id="endDate"
                                    className="mr-2 min-w-[200px]"
                                />

                                <button
                                    className="ti-btn !py-1 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary mr-2"
                                    onClick={handleApplyFilters}
                                >
                                    Apply Filter
                                </button>
                                <button
                                    className="ti-btn !py-1 !px-2 !text-[0.75rem] !text-white !font-medium bg-danger mr-2"
                                    onClick={clearFilters}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>

                        {!customers?.length ?
                            <EmptyData icon="shopping-cart-line" text="No customers found" />
                            :

                            <>
                                <div className="box-body">
                                    <div className="table-responsive mb-4">
                                        <table className="table whitespace-nowrap table-bordered min-w-full">
                                            <thead>
                                                <tr className="border-b border-defaultborder">
                                                    <th scope="col" className="text-start">Sl.No</th>
                                                    <th scope="col" className="text-start">Name</th>
                                                    <th scope="col" className="text-start">Email</th>
                                                    <th scope="col" className="text-start">Phone number</th>
                                                    <th scope="col" className="text-start">Onboarding Date</th>
                                                    <th scope="col" className="text-start">Customer status</th>
                                                    <th scope="col" className="text-start">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {customers.map((item) => {
                                                    return (
                                                        <tr key={item.id} className={`${item.class1} border-b border-defaultborder`}>
                                                            <td scope="row">
                                                                {pagination ? ((pagination?.currentPage - 1) * pagination?.limit + customers.indexOf(item) + 1) : orders.indexOf(item) + 1}</td>
                                                            <td>
                                                                <TextBreaker text={`${item?.firstName} ${item?.lastName}`} />
                                                            </td>
                                                            <td>{item?.email}</td>
                                                            <td>+{item?.phoneCode} {item?.phoneNumber}</td>
                                                            <td>
                                                                {dayjs(Number(item.createdAt)).format('DD-MM-YYYY')}
                                                            </td>
                                                            <td>{item?.status}</td>
                                                            <td>
                                                                <div className="space-x-1 rtl:space-x-reverse flex items-center">

                                                                    <div className="relative inline-flex items-center">
                                                                        <Toggle label="" onChange={() => handleBlockOrUnblockClick(item)} value={item.status == "BLOCKED"} />

                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {pagination && (
                                    <Pagination pagination={pagination} setPagination={setPagination} rows={customers} />
                                )}
                            </>
                        }
                    </div>
                </div>
            </div>
            {isModalVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <h3 className="text-lg font-semibold text-center">Are you sure you want to {selectedUser.status === CUSTOMER_STATUS.BLOCKED ? "unblock" : "block"} this customer?</h3>
                        <div className="mt-4 flex justify-center space-x-4">
                            <button onClick={handleBlockOrUnblock} className="bg-danger text-white px-4 py-2 rounded hover:bg-red-700" disabled={blockLoading}>
                                {selectedUser.status === CUSTOMER_STATUS.BLOCKED ? "Unblock" : "Block"}
                                {blockLoading && (
                                <span className="loading">
                                    <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                                </span>
                                )}
                            </button>
                            <button
                                onClick={() => setIsModalVisible(false)}
                                className="bg-primary text-white px-4 py-2 rounded hover:bg-blue-700"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CustomerList