import * as Yup from 'yup';

export const availabilityValidationSchema = () => {
  return {
    dateStart: Yup.date().when(['timed', '$dateAll'], {
      is: (timed, dateAll) => timed && !dateAll,
      then: (schema) => schema.required('Start date is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    dateEnd: Yup.date()
      .when(['timed', '$dateAll'], {
        is: (timed, dateAll) => timed && !dateAll,
        then: (schema) => schema.required('End date is required'),
        otherwise: (schema) => schema.notRequired(),
      })
      .test('is-after-start-date', 'End date must be after start date', function (value) {
        const { dateStart } = this.parent;
        return !dateStart || !value || value >= dateStart;
      }),
    timeStart: Yup.string().when(['timed', '$timeAll'], {
      is: (timed, timeAll) => timed && !timeAll,
      then: (schema) => schema.required('Start time is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    timeEnd: Yup.string().when(['timed', '$timeAll', '$daysAll'], {
      is: (timed, timeAll, daysAll) => timed && !timeAll,
      then: (schema) => schema.required('End time is required'),
      otherwise: (schema) => schema.notRequired(),
    }).test('is-after-start-time', 'End time must be after start time for the same day', function (value) {
      const { dateStart, dateEnd, timeStart } = this.parent;

      // If dates are the same, validate time
      if ((dateStart && dateEnd && dateStart.getTime() === dateEnd.getTime())) {
        return !timeStart || !value || value > timeStart;
      }

      // If dates are different, no need to validate time
      return true;
    }),
    days: Yup.array().when(['timed', '$daysAll'], {
      is: (timed, daysAll) => timed && !daysAll,
      then: (schema) => schema.min(1, 'At least one day is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  };
};