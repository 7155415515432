import { useState, useEffect } from 'react'
import PageHeader from '@common/src/pageheader/pageheader'
import Pagination from '@hub/components/common/Pagination/pagination'
import SearchBox from '@common/src/common/inputbox/searchBox'
import { useNavigate } from 'react-router-dom'
import Toggle from '@common/src/common/Toggle/Toggle'
import { useToast } from '@common/src/common/Toast/ToastProvider';
import { calcOffset } from '@common/src/common/helper'
import FilterDropdown from '@common/src/common/inputbox/filterDrop'
import { ALL_OPTION, CARD_STATUS, CARD_STATUS_OPTIONS } from '@hub/constants/constants'
import LoaderSm from '@common/src/common/loader/loader-sm'
import { getAllStores } from '@common/src/service/firebase/api'
import fetchHelper from '@common/src/lib/fetchHelper'
import DatePicker from 'react-datepicker'

const ValuePlusCards = () => {
    const params = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const toast = useToast();
    const [rows, setRows] = useState([])
    const [filters, setFilters] = useState({
        ...params.get('status') && { status: params.get('status') },
        ...params.get('storeId') && { storeId: params.get('storeId') },
        ...params.get('expireStart') && { expireStart: params.get('expireStart') },
        ...params.get('expireEnd') && { expireEnd: params.get('expireEnd') }
    });
    const [searchQuery, setSearchQuery] = useState(params.get('search') || '');
    const [toggleLoader, setToggleLoader] = useState({ loading: false, id: null })
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(false);
    const cardsStatusOptions = [ALL_OPTION.status, ...CARD_STATUS_OPTIONS]
    const defaultPagination = {
        limit: parseInt(params.get('limit')) || 20,
        offset: parseInt(params.get('offset')) || 0,
        currentPage: parseInt(params.get('currentPage')) || 1,
        totalPages: 0,
        totalRecords: 0
    }
    const [pagination, setPagination] = useState(defaultPagination)
    const [info, setInfo] = useState('');

    useEffect(() => {
        setLoading(true);
        getAllStores().then(res => {
            setStores([ALL_OPTION.stores, ...res]);
        })
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchHelper.get('/card', {
                limit: pagination.limit,
                offset: calcOffset(searchQuery, pagination.currentPage, pagination.limit, pagination.totalRecords),
                ...searchQuery && { search: searchQuery },
                ...filters && { filters: JSON.stringify(filters) }
            });
            if (response?.data?.rows?.length) {
                setRows(response?.data.rows)
                setPagination({
                    ...defaultPagination,
                    totalRecords: response?.data.totalRecords,
                    totalPages: response?.data.totalPages,
                })
            } else {
                setInfo('No Cards found.');
                setRows([]);
            }
            setLoading(false);
        };
        fetchData();
    }, [pagination.currentPage, window.location.href, filters, searchQuery]);

    const onStatusToggle = async (e, item) => {
        setToggleLoader({ loading: true, id: item.id })
        try {
            await fetchHelper.post(`/card/block-unblock`, {
                id: item.id,
                blockCard: e.target.checked
            })
            // Update the state to reflect the change
            setRows(prevRows =>
                prevRows.map(row => {
                    return row.id === item.id ? { ...row, status: e.target.checked ? item.prevStatus : CARD_STATUS.BLOCKED } : row
                }
                )
            );
            setToggleLoader({ loading: false, id: '' })
            toast.success(`Card status updated to ${e.target.checked ? 'unblocked' : 'blocked'}`, { type: 'success' });
        } catch (error) {
            setToggleLoader({ loading: false, id: '' })
            console.log(error)
            toast.error('Failed to update card status', { type: 'error' });
        }
    };
    // Function to handle the search input change
    const onSearchCard = (query) => {
        // Update the search query state
        setSearchQuery(query);
        if (query) {
            params.set('search', query);
        } else {
            params.delete('search');
        }
        navigate(`?${params.toString()}`);
    };
    const onFilterChange = (e, fieldName) => {
        // Update the search query state
        setFilters({
            ...filters,
            [fieldName]: e.value === 'all' ? undefined : e.value
        });
        // If there is a query, set it as a search parameter
        if (e.value !== 'all') params.set(fieldName, e.value.toString().toUpperCase());
        else params.delete(fieldName);

        // Update the URL with the new offset and currentPage
        params.set('offset', 0);
        params.set('currentPage', 1);
        // Navigate to the new URL with the updated search parameters
        navigate(`?${params.toString()}`);
    };
    const onDateChange = ({ start, end }) => {
        setFilters({
            ...filters,
            expireStart: start || undefined,
            expireEnd: end || undefined,
        })
        if (start) params.set('expireStart', start);
        else params.delete('expireStart');

        if (end) params.set('expireEnd', end);
        else params.delete('expireEnd');
        navigate(`?${params.toString()}`);
    }
    return (
        <>
            <PageHeader currentpage="Value Plus Cards" activepage="Value Plus Cards" mainpage="Value Plus Cards" />
            <div className="grid grid-cols-12 gap-6">
                <div className="xl:col-span-12 col-span-12">
                    <div className="box">
                        <div className="box-header flex items-center justify-between">
                            <div className="box-title">Cards</div>
                            <div className="flex align-center flex-wrap gap-2">
                                <FilterDropdown
                                    className="mr-2 xl:w-[200px] w-full"
                                    options={stores}
                                    value={
                                        filters.storeId ? stores.find((option) => option.value === filters?.storeId)
                                            : ALL_OPTION.stores
                                    }
                                    onChange={(e) => { onFilterChange(e, 'storeId') }}
                                />
                                <FilterDropdown
                                    className="mr-2 xl:w-[200px] w-full"
                                    options={cardsStatusOptions}
                                    value={
                                        filters.status ?
                                            cardsStatusOptions.find((option) => option.value === filters?.status)
                                            : ALL_OPTION.status
                                    }
                                    onChange={(e) => { onFilterChange(e, 'status') }}
                                />
                                <div className="flex mr-2" >
                                    <DatePicker
                                        selected={filters.expireStart}
                                        onChange={(dates) => {
                                            const [start, end] = dates;
                                            onDateChange({ start, end })
                                        }}
                                        selectsRange
                                        startDate={filters.expireStart}
                                        endDate={filters.expireEnd}
                                        placeholderText="Select Expire Date Range"
                                        className="form-control xl:w-[200px] w-full"
                                        dateFormat="dd/MM/yyyy"
                                        isClearable

                                    />
                                </div>
                                <SearchBox
                                    placeHolder="Search by card/phone number"
                                    value={searchQuery} onChange={(e) => onSearchCard(e.target.value)}
                                    classes='!py-1.5 !px-1 !mr-2 xl:w-[200px] w-full'
                                />
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="table-responsive mb-4">
                                <table className="table whitespace-nowrap table-bordered min-w-full">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-start">
                                                Sl.No
                                            </th>
                                            <th scope="col" className="text-start">
                                                Card Number
                                            </th>
                                            <th scope="col" className="text-start">
                                                Customer Name
                                            </th>
                                            <th scope="col" className="text-start">
                                                Phone Number
                                            </th>
                                            <th scope="col" className="text-start">
                                                Store Name
                                            </th>
                                            <th scope="col" className="text-start">

                                                Expiry Date
                                            </th>
                                            <th scope="col" className="text-start">
                                                Current Balance
                                            </th>
                                            <th scope="col" className="text-start">
                                                Action(Block/Unblock)
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td colSpan={8} className="text-center">
                                                    <div className="d-flex justify-content-center">
                                                        <div className="spinner-border">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {rows?.length > 0 ? (
                                                    rows.map((item) => (
                                                        <tr className="product-list" key={item.id}>
                                                            <td>{(pagination.currentPage - 1) * pagination.limit + rows.indexOf(item) + 1}</td>
                                                            <td>
                                                                <span className="font-semibold">{item?.cardNumber}</span>
                                                            </td>
                                                            <td>
                                                                {`${item?.customerId ? item?.customer?.firstName + ' ' + item?.customer?.lastName : '-'}`}
                                                            </td>
                                                            <td>{item?.phoneNumber}</td>
                                                            <td>{item?.store?.outletCode}<br />
                                                                {item?.store?.address?.city?.en || item?.store?.address?.city?.ar}
                                                            </td>
                                                            <td>{new Date(item?.expireAt).toLocaleDateString('en-GB')}</td>
                                                            <td>{`${item?.currency} ${item?.balance || 0}`}</td>
                                                            <td>
                                                                <div className="relative inline-flex items-center">
                                                                    <Toggle label=""
                                                                        value={item.status === CARD_STATUS.BLOCKED}
                                                                        disabled={toggleLoader.loading && toggleLoader.id}
                                                                        onChange={(e) => onStatusToggle(e, item)} />
                                                                    {(toggleLoader.loading && toggleLoader.id === item.id) && (
                                                                        <div className="absolute inset-0 bg-opacity-50 bg-gray-100 w-full ml-3">
                                                                            <LoaderSm />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={8} className="text-center">
                                                            <div className="alert alert-info">
                                                                {info}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                pagination={pagination}
                                setPagination={setPagination}
                                rows={rows}
                                searchQuery={searchQuery}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ValuePlusCards
