import InputBox from '@common/src/common/inputbox/inputbox'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'
import SelectBox from '@common/src/common/selectbox/selectbox'
import { PROMO_CODE_TYPES, STATUS_OPTIONS } from '@hub/constants/constants'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { getCategories } from '@common/src/service/firebase/categories'
import { addPromoCodes, updatePromoCodes } from '@hub/service/firebase/promoCodes'
import PropTypes from 'prop-types'
import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'
import { timeStamptoDate } from '@common/src/common/helper'
import Dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { getStore } from '@hub/service/firebase/store'
const validationSchema = Yup.object({
  code: Yup.string().required('Promo code is required').max(10, 'Promo Code cannot exceed 10 characters'),
  type: Yup.string().required('Promo code type is required'),
  value: Yup.number()
    .required('Discount value is required')
    .test('max-value', 'Discount value must be less than or equal to Minimum Order Value', function (value) {
      const { minOrderValue, type } = this.parent
      if (type === 'FLAT') {
        return value <= minOrderValue
      } else if (type === 'PERCENTAGE') {
        if (value > 100) {
          return this.createError({
            path: this.path,
            message: 'Percentage value must not exceed 100.',
          })
        }
      }
      return true
    }),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date()
    .required('End date is required')
    .test('is-after-start-date', 'End time must be after start time for the same day', function (value,context) {
      const startDate = context.parent.startDate;
      if (!startDate || !value) {
        return true; // Skip validation if either date is not set
      }
      const start = new Date(startDate);
      const end = new Date(value);
      return !(start.toDateString() === end.toDateString() && start.getTime() >= end.getTime());
    }),
  maxUsesPerCustomer: Yup.number()
    .required('Maximum number of uses count is required')
    .min(1, 'Maximum number of uses count must be greater than zero'),
  minOrderValue: Yup.number()
    .required('Minimum order value is required')
    .min(1, 'Minimum order value must be greater than zero'),
  maxDiscount: Yup.number().required('Maximum discount is required'),
  termsandconditions: Yup.object().shape({
    en: Yup.string()
      .required('English Terms and condition  is required')
      .min(10, 'English Terms and condition must be at least 10 characters long')
      .max(500, 'English Terms and condition cannot exceed 500 characters'),
    ar: Yup.string()
      .required('Arabic Terms and condition  is required')
      .min(10, 'Arabic Terms and condition must be at least 10 characters long')
      .max(500, 'Arabic Terms and condition cannot exceed 500 characters'),
  }),

  description: Yup.object()
    .shape({
      en: Yup.string()
        .required('English description is required')
        .max(200, 'English description cannot exceed 200 characters'),
      ar: Yup.string()
        .required('Arabic description is required')
        .max(200, 'Arabic description cannot exceed 200 characters'),
    })
    .required('Description is required'),
})
const PromoCodeForm = ({ id, promoData, isEdit, onComplete }) => {
  const [stores, setStores] = useState([])
  const [categories, setCategories] = useState([])
  const { selectedCompany } = useSelector((state) => state.auth)
  const [initialValues, setInitialValues] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  Dayjs.extend(utc)
  Dayjs.extend(timezone)
  const companyTimeZone = selectedCompany?.timeZone
  const getInitialData = async () => {
    setInitialValues({
      code: promoData?.code || '',
      type: promoData?.type || '',
      value: promoData?.value || 0,
      status: promoData?.status
        ? { value: promoData?.status, label: promoData?.status }
        : { value: 'ACTIVE', label: 'ACTIVE' },
      startDate: timeStamptoDate(promoData?.rules?.startDate, companyTimeZone),
      endDate: timeStamptoDate(promoData?.rules?.endDate, companyTimeZone),

      maxUsesPerCustomer: promoData?.maxUsesPerCustomer || 0,
      minOrderValue: promoData?.rules.minOrderValue || 0,
      maxDiscount: promoData?.rules.maxDiscount || 0,
      validStores: promoData?.rules.validStores || [],
      productCategory: promoData?.categoryId || [],
      termsandconditions: promoData?.termsandconditions || {},
      description: promoData?.promoDetails?.description || {},
    })
  }
  useEffect(() => {
    getInitialData()
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchCategories = getCategories(selectedCompany)
        const fetchStores = getStore(selectedCompany)
        const [categories, stores] = await Promise.all([fetchCategories, fetchStores])
        const setStoresData = stores?.map((item) => ({
          value: item.id,
          label: item.name?.en + ' , ' + item?.address?.city?.en || "",
        }))
        setStores(setStoresData)
        // Process roles
        const getCategoriesData = categories?.map((doc) => ({
          value: doc?.categoryId,
          label: doc?.name?.en,
        }))
        setCategories(getCategoriesData)
      } catch (error) {
        console.error('Error fetching data: ', error)
      }
    }

    fetchData()
  }, [])
  const handleSubmit = async (values, actions) => {
    setIsLoading(true)
    const formData = {
      code: values.code,
      type: values.type,
      value: values.value,
      status: values.status.value,
      maxUsesPerCustomer: values.maxUsesPerCustomer,
      categoryId: values.productCategory,
      termsandconditions: values.termsandconditions,
      rules: {
        startDate: Dayjs.tz(values.startDate, companyTimeZone).utc(),
        endDate: Dayjs.tz(values.endDate, companyTimeZone).utc(),
        minOrderValue: values.minOrderValue,
        maxDiscount: values.type === 'FLAT' ? values.value : values.maxDiscount,
        validStores: values.validStores,
      },
      promoDetails: {
        description: values.description,
      },
      companyId: selectedCompany.value,
    }
    try {
      if (isEdit) {
        await updatePromoCodes(promoData.id, formData, selectedCompany.value);
      } else {
        await addPromoCodes(formData, selectedCompany.value);
      }
      actions?.resetForm();
      onComplete?.();
      HSOverlay.close(`#${id}`); // Close the form overlay
    } catch (error) {
      console.error('Error submitting promo code:', error);
    } finally {
      setIsLoading(false); // Stop loading
      actions?.setSubmitting(false);
  }
}


  return (
    <OffCanvasForm
      enableReinitialization
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText={isEdit ? 'Close' : 'Cancel'}
      id={id}
      autoClose={false}
      loading={isLoading}
    >
      {({ setFieldValue, values, errors, touched }) => (
        <div className="grid grid-cols-12 gap-6 ">
          <div className="xl:col-span-12 col-span-12">
            <div className="box">
              <div className="box-body add-products !p-0">
                <div className="grid grid-cols-1 gap-6">
                  <InputBox
                    type="text"
                    id="code"
                    name="code"
                    label="Promo Code"
                    placeholder="Enter Promo Code "
                    value={values.code}
                    onChange={(e) => setFieldValue('code', e.target.value)}
                    required={true}
                    errorText={touched.code && errors.code ? errors.code : null}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <MultiLanguageInput
                    type="input"
                    label="Description"
                    id="description"
                    name="description"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                    required={true}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <MultiLanguageInput
                    type="input"
                    id="termsandconditions"
                    name="termsandconditions"
                    label="Terms and condition of usage "
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                    required={true}
                  />
                </div>
                <div className="col-span-12">
                  <SelectBox
                    id="type"
                    label="Choose Promo Code Type"
                    options={PROMO_CODE_TYPES}
                    name="type"
                    value={PROMO_CODE_TYPES.find((option) => option.value === values.type)}
                    onChange={(e) => setFieldValue('type', e.value)}
                    required={true}
                    errorText={touched.type && errors.type ? errors.type : ''}
                  />
                </div>
                {values.type !== 'FREE_DELIVERY' && (
                  <div className="grid grid-cols-1 gap-6">
                    <InputBox
                      type="number"
                      id="value"
                      name="value"
                      label="Discount Value"
                      placeholder="Enter Discount Value"
                      value={values.value}
                      onChange={(e) => setFieldValue('value', parseFloat(e.target.value))}
                      required={true}
                      errorText={touched.value && errors.value ? errors.value : null}
                      min={0}
                    />
                  </div>
                )}
                <div className="grid grid-cols-1 gap-6">
                  <InputBox
                    type="number"
                    id="maxUsesPerCustomer"
                    name="maxUsesPerCustomer"
                    label="Enter Maximum Number of Uses"
                    placeholder="Enter Maximum Number of Uses"
                    value={values.maxUsesPerCustomer}
                    min={0}
                    onChange={(e) =>
                      setFieldValue('maxUsesPerCustomer', e.target.value === '' ? '' : parseFloat(e.target.value))
                    }
                    required={true}
                    errorText={
                      touched.maxUsesPerCustomer && errors.maxUsesPerCustomer ? errors.maxUsesPerCustomer : null
                    }
                  />
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <InputBox
                    type="number"
                    id="minOrderValue"
                    name="minOrderValue"
                    label="Enter Minimum Order Value"
                    placeholder="Enter Minimum Order Value"
                    min={0}
                    value={values.minOrderValue}
                    onChange={(e) =>
                      setFieldValue('minOrderValue', e.target.value === '' ? '' : parseFloat(e.target.value))
                    }
                    required={true}
                    errorText={touched.minOrderValue && errors.minOrderValue ? errors.minOrderValue : null}
                  />
                </div>
                {values.type !== 'FREE_DELIVERY' && values.type !== 'FLAT' && (
                  <div className="grid grid-cols-1 gap-6">
                    <InputBox
                      type="number"
                      id="maxDiscount"
                      name="maxDiscount"
                      label="Enter Maximum Discount"
                      placeholder="Enter Maximum Discount"
                      min={0}
                      value={values.maxDiscount}
                      onChange={(e) =>
                        setFieldValue('maxDiscount', e.target.value === '' ? '' : parseFloat(e.target.value))
                      }
                      required={true}
                      errorText={touched.maxDiscount && errors.maxDiscount ? errors.maxDiscount : null}
                    />
                  </div>
                )}
                <SelectBox
                  id="productCategory"
                  label="Choose Applicable Product Category"
                  options={[{ value: 'all', label: 'All' }, ...categories]}
                  isMulti
                  name="productCategory"
                  value={
                    values.productCategory?.includes('all')
                      ? [{ value: 'all', label: 'All' }, ...categories]
                      : categories.filter((option) => values.productCategory?.includes(option.value))
                  }
                  onChange={(selectedOptions) => {
                    if (selectedOptions.some((option) => option.value === 'all')) {
                      setFieldValue(
                        'productCategory',
                        categories.map((category) => category.value)
                      )
                    } else {
                      setFieldValue(
                        'productCategory',
                        selectedOptions.map((option) => option.value)
                      )
                    }
                  }}
                  errorText={touched.productCategory && errors.productCategory ? errors.productCategory : ''}
                />
                <SelectBox
                  id="validStores"
                  label="Choose Applicable Stores"
                  options={[{ value: 'all', label: 'All' }, ...stores]}
                  isMulti
                  name="validStores"
                  value={
                    values.validStores?.includes('all')
                      ? [{ value: 'all', label: 'All' }, ...stores]
                      : stores.filter((option) => values?.validStores?.includes(parseInt(option.value)))
                  }
                  onChange={(selectedOptions) => {
                    if (selectedOptions.some((option) => option.value === 'all')) {
                      setFieldValue(
                        'validStores',
                        stores.map((store) => parseInt(store.value))
                      )
                    } else {
                      setFieldValue(
                        'validStores',
                        selectedOptions.map((option) => parseInt(option.value))
                      )
                    }
                  }}
                  errorText={touched.validStores && errors.validStores ? errors.validStores : ''}
                />

                <div className="grid grid-cols-1 gap-6">
                  <SelectBox
                    name="status"
                    label="Status"
                    value={values.status}
                    onChange={(e) => {
                      setFieldValue('status', e)
                    }}
                    id="status"
                    options={STATUS_OPTIONS}
                    errorText={touched.status && errors.status ? errors.status : null}
                  />
                </div>

                <div className="grid grid-cols-1 gap-6">
                  <div className="grid grid-cols-2 gap-6">
                    <InputBox
                      type="datetime-local"
                      name="startDate"
                      min={!isEdit && new Date().toISOString().split('T')[0] + 'T00:00'}
                      errorText={touched.startDate && errors.startDate ? errors.startDate : null}
                      id="startDate"
                      required
                      label="Start Date"
                      placeholder="Enter Start Date"
                      value={values.startDate}
                      onChange={(e) => setFieldValue('startDate', e.target.value)}
                    />
                    <InputBox
                      name="endDate"
                      label="End Date"
                      placeholder="Enter End Date"
                      value={values.endDate}
                      onChange={(e) => setFieldValue('endDate', e.target.value)}
                      min={values.startDate}
                      type="datetime-local"
                      errorText={touched.endDate && errors.endDate ? errors.endDate : null}
                      id="endDate"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </OffCanvasForm>
  )
}

PromoCodeForm.propTypes = {
  id: PropTypes.string.isRequired,
  promoData: PropTypes.object,
  isEdit: PropTypes.bool,
  onComplete: PropTypes.func,
}

export default PromoCodeForm
