import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas'
import TableList from '@common/src/common/Table/TableList'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas'
import Pageheader from '@common/src/pageheader/pageheader'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { COLLECTIONS, PROMOTIONS } from '@common/src/constants/constants'
import { collection, deleteDoc, doc } from 'firebase/firestore'
import { db } from '../../../firebase/firebaseapi'
import PromoCodeForm from './PromoCodeForm'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { getStatus } from '@common/src/common/helper'

const getColumns = (onEditClick, onDeleteClick, timeZone) => [
    { title: 'Name', dataIndex: 'code', key: 'name' },
    { title: 'Type', dataIndex: 'type', key: 'type' },
    { title: 'Discount Value', dataIndex: 'value', key: 'value' },
    { title: 'Status', dataIndex: 'status', key: 'status', render: (text) => <>{text}</> }, 
    {
        title: 'Live/Offline',
        key: 'liveStatus',
        render: (_, record) => {
            const status = getLiveStatus(record.rules.startDate, record.rules.endDate, timeZone);
            return <span className={status === 'Live' ? 'text-green-500' : 'text-red-500'}>{status}</span>;
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => (
            <div className="space-x-1 rtl:space-x-reverse">
                <button onClick={() => onEditClick(record)} className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm">
                    <i className="ri-pencil-line"></i>
                </button>
                <button onClick={() => onDeleteClick(record)} className="ti-btn ti-btn-danger ti-btn-icon ti-btn-sm">
                    <i className="ri-delete-bin-5-line"></i>
                </button>
            </div>
        ),
    },
]
const getLiveStatus = (startDate, endDate, timeZone) => {
    return getStatus(startDate, endDate, timeZone);
};

const Promocodes = () => {
    const tableRef = useRef(null)
    const { selectedCompany } = useSelector((state) => state.auth)
    const [dbRef, setDbRef] = useState(null)
    const [promoCodeData, setPromoCodeData] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedPromoCodeId, setSelectedPromoCodeId] = useState(null)
    const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()
    const toast = useToast()

    useEffect(() => {
        if (selectedCompany) {
            setDbRef(collection(db, `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${PROMOTIONS.PROMOCODES}`))
        }
    }, [selectedCompany])

    const handleEditClick = useCallback(
        (data) => {
            setPromoCodeData(data)
            setIsEdit(true)
            openOffCanvas('addPromoCode', 'Edit Promo Code')
        },
        [openOffCanvas]
    )

    const handleDeleteClick = (data) => {
        setSelectedPromoCodeId(data.id)
        setIsModalVisible(true)
      }


    const handleAddClick = () => {
        setPromoCodeData(null)
        setIsEdit(false)
        openOffCanvas('addPromoCode', 'Add Promo Code')
    }

    const onComplete = () => {
        if (tableRef.current) {
            tableRef.current.refreshCurrentPage()
            closeOffCanvas()
        }
    }

     
    const handleDelete = async () => {
        try {
            
          const promoCodeRef = doc(
            db,
             `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${PROMOTIONS.PROMOCODES}`,
            selectedPromoCodeId
          )
       
          await deleteDoc(promoCodeRef)
          toast.success('Promo Code deleted successfully')
    
          // Refresh the table to reflect the changes
          if (tableRef.current) {
            tableRef.current.refreshCurrentPage()
          }
    
          setIsModalVisible(false) // Close the modal after successful deletion
        } catch (error) {
          toast.error('Error deleting promo code')
          console.error('Error deleting promocode: ', error)
          setIsModalVisible(false) // Close the modal in case of an error
        }
      }


    const columns = useMemo(() => getColumns(handleEditClick, handleDeleteClick, selectedCompany.timeZone), [handleEditClick, handleDeleteClick, selectedCompany.timeZone])
    return (
        <>
            <Pageheader currentpage="Promo Codes" activepage="Promotions" mainpage="Promo Code" />
            <TableList
                name="Promo Codes"
                columns={columns}
                dbRef={dbRef}
                pagination={{ pageSize: 20 }}
                tools={{ btnName: 'Add Promo Codes', btnIcon: 'ri-add-line', btnClick: handleAddClick }}
                ref={tableRef}
            />
            <FloatingCanvas
                id={'hs-test-123'}
                onClose={() => closeOffCanvas(false)}
                isVisible={isVisible}
                close={closeOffCanvas}
                title={offCanvasTitle}
                okText="Submit"
                closeText="Close"
            >

                {['addPromoCode', 'editPromoCode'].includes(activeComponent) && (
                    <PromoCodeForm promoData={promoCodeData} isEdit={isEdit} onComplete={onComplete} />
                )}
            </FloatingCanvas>
            {isModalVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <h3 className="text-lg font-semibold text-center">Are you sure you want to delete this promo code?</h3>
                        <div className="mt-4 flex justify-center space-x-4">
                            <button onClick={handleDelete} className="bg-danger text-white px-4 py-2 rounded hover:bg-red-700">
                                Delete
                            </button>
                            <button
                                onClick={() => setIsModalVisible(false)}
                                className="bg-primary text-white px-4 py-2 rounded hover:bg-blue-700"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Promocodes